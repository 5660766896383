<template>
  <div>
    <v-row dense>
      <template v-if="step === 1">
        <v-col cols="12">
          <v-autocomplete
            v-model="type"
            :items="typeOptions"
            :label="$t('labels.action')"
            dense
            outlined
            hide-details
            class="c-input-small"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" v-if="type === 1">
          <v-autocomplete
            v-model="id_supplier"
            :items="suppliers"
            :label="$t('labels.supplier')"
            dense
            outlined
            hide-details
            class="c-input-small"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" v-else-if="type > 1">
          <select-employee-model
            v-model="id_employee_receipt"
            :label="$t('labels.employee')"
          ></select-employee-model>
        </v-col>
        <v-col cols="12">
          <v-btn
            color="success"
            block
            :disabled="!type || (!id_employee_receipt && !id_supplier)"
            @click="nextStep"
          >
            {{ $t("labels.start") }}
          </v-btn>
        </v-col>
      </template>

      <template v-if="step === 2">
        <v-col cols="4">
          <select-pos-model
            v-model="idPos"
            :label="$t('labels.pos')"
            @change="onPosChange"
          ></select-pos-model>
        </v-col>
        <v-col cols="8">
          <input-qr-scan-model
            ref="inputQr"
            v-model="code"
            label="IMEI | UID"
            @keyupEnter="inputScan"
            :disabled="!idPos"
          ></input-qr-scan-model>
        </v-col>
        <v-col cols="12">
          <v-btn
            color="success"
            block
            :disabled="items.length === 0 || !idPos"
            @click="finishExport"
          >
            {{ $t("labels.finish") }}
          </v-btn>
        </v-col>
        <v-col cols="12">
          <v-simple-table class="table-padding-2">
            <template v-slot:default>
              <thead class="v-data-table-header">
                <tr>
                  <th class="text-center">IMEI | UID</th>
                  <!-- <th class="text-center">Danh mục</th> -->
                  <th class="text-center">Tên sản phẩm</th>
                  <th class="text-center">Đặc tả</th>
                  <th class="text-center">Size</th>
                  <th class="text-center" style="min-width: 150px">Ghi chú</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  class="text-center"
                  v-for="(item, index) in items"
                  :key="`i_${index}_${item.id}`"
                >
                  <td>{{ item.sku_uid }}</td>
                  <!-- <td></td> -->
                  <td>{{ item.name }}</td>
                  <td>{{ item.description }}</td>
                  <td>{{ item.size }}</td>
                  <td>
                    <v-text-field
                      v-model="item.note"
                      class="c-input-xs"
                      dense
                      outlined
                      hide-details
                      single-line
                    ></v-text-field>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </template>

      <v-col cols="12" class="mt-8">
        <ExportDefectiveHistory />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";

export default {
  name: "ExportDefective",
  components: {
    ExportDefectiveHistory: () =>
      import("@/components/pos_goods/ExportDefectiveHistory"),
  },
  data: () => ({
    idPos: null,
    code: null,
    isLoading: false,
    typeOptions: [
      {
        text: "Trả nhà cung cấp",
        value: 1,
      },
      {
        text: "Hủy sản phẩm",
        value: 2,
      },
      {
        text: "Sửa chữa",
        value: 3,
      },
      {
        text: "Bán thanh lý",
        value: 4,
      },
    ],
    type: null,
    id_employee_receipt: null,
    id_supplier: null,
    step: 1,
    suppliers: [],
    items: [],
  }),
  mounted() {
    if (
      window.me &&
      window.me.working &&
      window.me.working.export_defective &&
      window.me.working.export_defective_item
    ) {
      const working = JSON.parse(window.me.working.export_defective_item);
      this.type = working.type;
      this.id_employee_receipt = working.id_employee_receipt;
      this.id_supplier = working.id_supplier;
      this.getProcessing();
    }
    this.getSuppliers();
  },
  methods: {
    async inputScan() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        const { data } = await httpClient.post("/get-qc-fail-item", {
          code: this.code,
          id_pos: this.idPos,
          id_employee_receipt: this.id_employee_receipt,
          id_supplier: this.id_supplier,
          type: this.type,
        });

        this.code = null;
        this.isLoading = false;

        this.items.unshift(data);
        this.$root.$emit("playSuccessAudio");
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.code = null;
        this.isLoading = false;
        this.$root.$emit("playErrorAudio");
      }
    },
    async getProcessing() {
      const { data } = await httpClient.post(
        "/get-processing-export-defective"
      );
      if (data && data.length > 0) {
        this.items = [...data];
        this.step = 2;
      }
    },
    async finishExport() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        const { data } = await httpClient.post("/finish-export-defective", {
          items: this.items,
          id_pos: this.idPos,
          id_employee_receipt: this.id_employee_receipt,
          id_supplier: this.id_supplier,
          type: this.type,
        });

        this.isLoading = false;
        this.$root.$emit("playSuccessAudio");
        this.resetValue();

        this.updateWorking({
          type: "export_defective",
          item: null,
        });
        this.downloadExcelFile(
          "/goods-export-defective-excel",
          {
            id: data,
            type_to_text: {
              1: "TRẢ NHÀ CUNG CẤP",
              2: "HỦY SẢN PHẨM",
              3: "SỬA CHỮA",
              4: "BÁN THANH LÝ",
            },
          },
          "A4_ban_ban_xuat_hang_loi.xlsx"
        );
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.code = null;
        this.isLoading = false;
        this.$root.$emit("playErrorAudio");
      }
    },
    onPosChange() {
      this.focusInputQr();
    },
    focusInputQr() {
      setTimeout(() => {
        console.log(this.idPos);
        this.$refs.inputQr.focusInput();
      }, 200);
    },
    resetValue() {
      this.type = null;
      this.id_supplier = null;
      this.id_employee_receipt = null;
      this.idPos = null;
      this.items = [];
    },
    nextStep() {
      this.updateWorking({
        type: "export_defective",
        item: JSON.stringify({
          type: this.type,
          id_supplier: this.id_supplier,
          id_employee_receipt: this.id_employee_receipt,
        }),
      });
      this.step = 2;
    },
    async getSuppliers() {
      const { data } = await httpClient.post("/get-supplier-goods-by-employee");
      this.suppliers = [...data].map((item) => ({
        text: `${item.name} - ${item.code}`,
        value: item.id,
      }));
    },
  },
};
</script>
